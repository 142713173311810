import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class TermsOfUsePageService {
    TermsOfUsePageContent$ = new BehaviorSubject({} as any);

    constructor(private http: HttpClient) { }

    init(): void {
        this.getContent().subscribe((res) => this.TermsOfUsePageContent$.next(res?.content));
    }

    getContent(): Observable<any> {
        return this.http.get("/assets/data/terms-of-use-page.json");
    }
}
