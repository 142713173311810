import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Capability } from "@shared/capability-carousel";
import { BenefitsService } from "@shared/common-pages/onboarding/benefits.service";
import { environment } from "@environments/environment";
import { ImagesService } from "@asset-owner-portal/shared/services/images.service";

const defaultImagesList = [
    "/assets/img/onboarding/asset-questions.svg",
    "/assets/img/onboarding/access_and_manage.svg",
    "/assets/img/onboarding/entitle_users.svg",
    "/assets/img/onboarding/asset-owner-portal.svg",
    "/assets/img/onboarding/publishing-request.svg",
    "/assets/img/onboarding/review-followup.svg",
    "/assets/img/onboarding/living-portal.svg",
]

@Component({
    selector: "deloitteai--onboarding-page",
    templateUrl: "./onboarding.html",
    styleUrls: ["./onboarding.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OnboardingPage implements OnInit {
    @ViewChild("questionaire", { read: ElementRef, static: true })
    private questionaire: ElementRef;

    videoOptions: Object = {
        autoplay: false,
        fluid: true,
        html5: true,
        poster: "assets/img/video-cover@2x.png",
        muted: false,
        sources: [
            {
                src: environment.onboarding.video_url,
                type: "video/mp4",
            },
        ],
    };

    benefits: Capability[];
    images: string[] = defaultImagesList;

    backendDevelopmentUrl = environment.onboarding.development.backend_url;
    frontendDevelopmentUrl = environment.onboarding.development.frontend_url;
    businessSupportEmailAddress = environment.onboarding.development.business_support_email;
    startQuestionnaireUrl = environment.aoh_url + "/start";
    visitAssetOwnerHubUrl = environment.aoh_url;
    displayVideo = environment.onboarding.displayVideo;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scrollTo($event: MouseEvent, target: string): void {
        $event.preventDefault();
        $event.stopPropagation();

        const offset = (
            this.questionaire.nativeElement as HTMLElement
        ).getBoundingClientRect();
        window.scroll({ top: offset.y - 70, left: 0 });
    }

    constructor(private readonly benefitsService: BenefitsService, private readonly imagesService: ImagesService) { }

    ngOnInit(): void {
        this.benefitsService.init();
        this.benefitsService.benefits$.subscribe(
            (benefits) => (this.benefits = benefits)
        );

        this.imagesService.images$.subscribe((images) => {
            this.images =  [];
            this.images = images?.onboarding || defaultImagesList;
            console.log(images?.onboarding)
        });
    }
}
