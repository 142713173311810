import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "@environments/environment";
import { HeaderMenuItem } from "./header.models";

const menuLabels = [
    "HEADER_MENU_USER_ORDER_HISTORY",
    "HEADER_MENU_USER_ENTITLEMENTS",
];

@Pipe({
    name: "menuItem",
    pure: true,
})
export class MenuItemPipe implements PipeTransform {
  environment = environment;

  transform(item: HeaderMenuItem): any {
      return menuLabels.includes(item?.label)
          ? this.environment.store_url + "/" + item?.route
          : item?.route || this.environment[item?.["envKey"]];
  }
}
