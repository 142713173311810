<deloitteai-page-banner class="grid-10">

    <img src="./assets/img/onboarding-bannerimg.png" bannerImage>

    <ng-container bannerHeader>
        <h3 class="header">{{'ONBOARDING.BANNER.HEADER' | translate}}</h3>
    </ng-container>

    <ng-container bannerBody>
        <p class="text">{{'ONBOARDING.BANNER.TEXT' | translate}}</p>
    </ng-container>

    <ng-container bannerFooter>
        <a class="btn btn-primary mt-2 w-xs-100 cursor-pointer" (click)="scrollTo($event, 'questionaire')">
            {{'CALL_TO_ACTION.DISCOVER_ONBOARDING' | translate}}
        </a>
    </ng-container>

</deloitteai-page-banner>

<div class="container m-auto px-3">

    <div class="row mb-5 pb-3">
        <div class="col-md-10 offset-md-1">
            <!-- capabilities slider -->
            <div class="article">
                <div class="article__header mb-5 text-center">
                    {{'ONBOARDING.SECTION.BENEFITS.HEADER' | translate}}
                </div>
                <div *ngIf="benefits?.length > 0">
                    <deloitteai-capability-carousel [capabilities]="benefits"></deloitteai-capability-carousel>
                </div>
            </div>
        </div>
    </div>

    <!--
    accelerating development process
  -->
    <div class="row mb-5 pb-3">
        <div class="col-md-10 offset-md-1">
            <div class="article">
                <div class="article__header mb-5">
                    {{'ONBOARDING.SECTION.ACCELERATE.DEVELOPMENT.HEADER' | translate}}
                </div>

                <div class="article__body">
                    <p>
                        {{'ONBOARDING.SECTION.ACCELERATE.DEVELOPMENT.TEXT' | translate}}
                    </p>

                    <deloitteai-benefits>
                        <ng-template deloitteaiBenefitsItem>
                            <a [innerHTML]="'ONBOARDING.FEATURES.BACKEND_DEVELOPMENT' | translate"
                                href="{{backendDevelopmentUrl}}">
                            </a>
                        </ng-template>

                        <ng-template deloitteaiBenefitsItem>
                            <a [innerHTML]="'ONBOARDING.FEATURES.FRONTEND_DEVELOPMENT' | translate"
                                href="{{frontendDevelopmentUrl}}">
                                ></a>
                        </ng-template>

                        <ng-template deloitteaiBenefitsItem>
                            <a [innerHTML]="'ONBOARDING.FEATURES.BUSINESS_SUPPORT' | translate"
                                href="{{businessSupportEmailAddress}}">
                            </a>
                        </ng-template>
                    </deloitteai-benefits>

                </div>
            </div>

        </div>
    </div>

    <!--
      STEP BY STEP
    -->
    <div class="article row flex-column" #questionaire>

        <h3 class="article__header mb-5">{{'ONBOARDING.ARTICLE.HEADER.GUIDANCE_ASSET_OWNER_PORTAL' | translate}}</h3>

        <div class="article__body timeline col-md-10 offset-md-1 mb-3">

            <!-- principial asset questions -->
            <div class="timeline__section">
                <div class="timeline__section--image">
                    <img [src]="images[0]" alt="publishing request">
                </div>

                <div class="timeline__section--card">

                    <div class="header">
                        {{'ONBOARDING.ASSET_QUESTIONS.HEADER' | translate}}
                    </div>

                    <div class="body">
                        <p [innerHTML]='"ONBOARDING.ASSET_QUESTIONS.BODY" | translate'>
                        </p>
                        <a class="btn btn-primary w-xs-100" href="{{startQuestionnaireUrl}}">
                            {{'CALL_TO_ACTION.START_QUESTIONNAIRE' | translate}}
                        </a>
                    </div>
                </div>
            </div>

            <!-- access and manage your assets -->
            <div class="timeline__section">
                <div class="timeline__section--image">
                    <img src="{{images[1]}}" alt="access and manage your asset">
                </div>

                <div class="timeline__section--card">

                    <div class="header">
                        {{'ONBOARDING.ACCESS_AND_MANAGE.HEADER' | translate}}
                    </div>

                    <div class="body">
                        <p [innerHTML]='"ONBOARDING.ACCESS_AND_MANAGE.BODY" | translate'>
                        </p>
                        <a class="btn btn-primary w-xs-100" href="{{visitAssetOwnerHubUrl}}">
                            {{'CALL_TO_ACTION.VISIT_ASSET_OWNER_HUB' | translate}}
                        </a>
                    </div>
                </div>
            </div>

            <!-- entitle users -->
            <div class="timeline__section">
                <div class="timeline__section--image">
                    <img src="{{images[2]}}" alt="entitle users">
                </div>

                <div class="timeline__section--card">

                    <div class="header">
                        {{'ONBOARDING.ENTITLE_USERS.HEADER' | translate}}
                    </div>

                    <div class="body">
                        <p [innerHTML]='"ONBOARDING.ENTITLE_USERS.BODY" | translate'>
                        </p>
                        <a class="btn btn-primary w-xs-100" href="{{visitAssetOwnerHubUrl}}">
                            {{'CALL_TO_ACTION.VISIT_ASSET_OWNER_HUB' | translate}}
                        </a>
                    </div>
                </div>
            </div>

            <!--
        ASSET OWNER PORTAL
      -->
            <div class="timeline__section">
                <div class="timeline__section--image">
                    <img src="{{images[3]}}" alt="principle asset questions">
                </div>

                <div class="timeline__section--card">

                    <div class="header">
                        {{'ONBOARDING.ASSET_OWNER_PORTAL.HEADER' | translate}}
                    </div>

                    <div class="body">
                        <p [innerHTML]='"ONBOARDING.ASSET_OWNER_PORTAL.BODY" | translate'>
                        </p>
                    </div>
                </div>
            </div>

            <!--
        PUBLISHING REQUEST
      -->
            <div class="timeline__section">
                <div class="timeline__section--image">
                    <img src="{{images[4]}}" alt="publishing request">
                </div>

                <div class="timeline__section--card">

                    <div class="header">
                        {{'ONBOARDING.PUBLISHING_REQUEST.HEADER' | translate}}
                    </div>

                    <div class="body">
                        <p [innerHTML]='"ONBOARDING.PUBLISHING_REQUEST.BODY" | translate'>
                        </p>
                    </div>
                </div>
            </div>

            <!--
        REVIEW AND FOLLOW-UP
      -->
            <div class="timeline__section">
                <div class="timeline__section--image">
                    <img src="{{images[5]}}" alt="review & follow-up">
                </div>

                <div class="timeline__section--card">

                    <div class="header">
                        {{'ONBOARDING.REVIEW_FOLLOWUP.HEADER' | translate}}
                    </div>

                    <div class="body">
                        <p [innerHTML]='"ONBOARDING.REVIEW_FOLLOWUP.BODY" | translate'>
                        </p>
                    </div>
                </div>
            </div>

            <!--
        LIVING PORTAL FOR FURTHER ADJUSTMENTS
      -->
            <div class="timeline__section">
                <div class="timeline__section--image">
                    <img src="{{images[6]}}" alt="living portal for further adjusments">
                </div>

                <div class="timeline__section--card">

                    <div class="header">
                        {{'ONBOARDING.LIVING_PORTAL.HEADER' | translate}}
                    </div>

                    <div class="body">
                        <p [innerHTML]='"ONBOARDING.LIVING_PORTAL.BODY" | translate'>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- start onboarding today -->
    <div class="row article mb-3">
        <div class="col-12 col-md-10 mx-auto article__header text-center mb-5">
            {{'ONBOARDING.ARTICLE.START_TODAY.HEADER' | translate}}
        </div>
        <div class="col-12 col-md-10 offset-md-1 mb-4">
            {{'ONBOARDING.ARTICLE.START_TODAY.INTRO' | translate}}
        </div>
        <deloitteai-video-player *ngIf="displayVideo" [options]="videoOptions" class="col col-md-10 m-auto m-bottom-3">
        </deloitteai-video-player>
    </div>

    <div class="row mb-5">
        <div class="col-12 col-md-10 mx-auto">{{'ONBOARDING.ARTICLE.START_TODAY.OUTRO' | translate}}</div>
        <div class="col-12 mt-4 text-center">
            <a class="btn btn-primary" href="{{visitAssetOwnerHubUrl}}">
                {{'CALL_TO_ACTION.START_QUESTIONNAIRE' | translate}}
            </a>
        </div>
    </div>

</div>