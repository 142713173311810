<ng-container *ngIf="isLoaded"><div [innerHtml]="content"></div></ng-container>

<!-- <div class=‘container h-100 mx-auto px-3’>
    <h1>Deloitte AI Marketplace Terms of Use</h1> <br> This Deloitte artificial intelligence marketplace site (“Deloitte
    AI Marketplace”) is made available by Deloitte Consulting GmbH (“Deloitte Consulting”), a German limited liability
    company, with an office located at 40476 Düsseldorf, Schwannstraße 6, to other member firms of DTTL or an affiliate
    thereof (each a “Deloitte Member Firm”) for the collaboration and sharing of materials, information and tools as well
    as for hosting and running of software solutions pursuant to the rules set out in these Terms of Use. <br>
    <h2>1. Access to the AI Marketplace</h2> a) In its request for access to the Deloitte AI Marketplace, the Deloitte
    Member Firm shall specify its corporate information and organizational contact and shall indicate whether it wishes to
    use the Deloitte AI Marketplace for selling and/or buying. By seeking and providing such access, each party is
    agreeing to these Terms of Use. <br><br> b) Via the master login (SSO of Deloitte Global Active Directory), the
    Deloitte Member Firm has the ability to grant its employees and employees of other Member Firms their own access
    authority and to configure such access in line with its own needs in order to facilitate optimum work on the Deloitte
    AI Marketplace. <br><br> c) Deloitte Member Firm confirms that the details furnished by it to Deloitte Consulting in
    regard to offering assets on the Deloitte AI Marketplace and to buying assets on the Deloitte AI Marketplace, are true
    and complete. Deloitte Member Firm undertakes that it shall promptly remove or update details Deloitte Member
    furnishes. <br><br> d) Deloitte Consulting is authorized to cancel a Deloitte Member Firm’s admission or to block its
    access to the Deloitte AI Marketplace where there are grounds to suspect that the Deloitte Member Firm has breached
    these Terms of Use. <br><br> e) All logins are individualized and may only be used by the authorized Deloitte Member
    Firm’s employee in question. Deloitte Member Firm’s employee must keep login and password secret and protect them
    against unauthorized access by third parties. Deloitte Member Firm is also responsible for keeping staff logins
    confidential and shall instruct its staff accordingly. In the event of any suspicion of misuse by a third party,
    Deloitte Member Firm shall promptly notify Deloitte Consulting thereof. As soon as Deloitte Consulting learns of such
    unauthorized use, Deloitte Consulting shall block access by the unauthorized User. <br><br> f) Deloitte Member Firm’s
    employee accessing the Deloitte AI Marketplace confirms that he or she has the authority to access the Deloitte AI
    Marketplace and to use it in the way described herein on behalf of the respective Deloitte Member Firm. <br><br>
    <h2>2. Term and Termination</h2> These Terms of Use will remain in effect for as long as Deloitte Consulting provides,
    and Deloitte Member Firm uses, the AI Marketplace for selling or purchasing assets under the AI Marketplace. Use of or
    access to the Deloitte AI Marketplace may be terminated by either party (i) without cause by giving thirty (30) days
    prior written notice to the other party; (ii) for the other party’s material breach by giving thirty (30) days prior
    written notice to the other party, provided that the other party shall have the right to cure the breach within the
    notice period; or (iii) if it determines that the performance of any part of this agreement would be in conflict with
    law, or independence or professional rules. Upon any termination, Deloitte Member Firm’s right to use and access the
    Deloitte AI Marketplace shall cease as of the effective date of such termination. <br>
    <h2>3. Deloitte Member Firm Responsibilities.</h2> a) Deloitte Member Firm shall be solely responsible for, among
    other things, (i) providing all Deloitte Member Firm and/or third party Data uploaded by the Deloitte Member Firm to
    the Deloitte AI Marketplace (“Data”), and the accuracy and completeness thereof; (ii) maintaining in effect at all
    times during the term of this Agreement all rights required for use of any Data in connection with the Deloitte AI
    Marketplace; and (iii) obtaining and providing Data in accordance with applicable laws. As between the parties,
    Deloitte Member Firm shall own or be otherwise entitled to use all Data and shall retain all intellectual property in
    such Data. In addition, Deloitte Member Firm shall be solely responsible for the performance of its personnel and
    maintaining all software, hardware and other equipment used by Deloitte Member Firm to access and use the Deloitte AI
    Marketplace. <br><br> b) Since the Deloitte AI Marketplace runs on Google Cloud Platform, Deloitte Member Firm will
    comply with (i) the Acceptable Use Policy (AUP), Attachment 7 Google Marketplace License Agreement which can be found
    under: https://cloud.google.com/terms/aup. <br><br> c) Deloitte Member Firm will not, and will not allow third parties
    under its control to: (i) use the Deloitte AI Marketplace for activities where the use or failure thereof could lead
    to death, personal injury, or environmental damage (such as operation of nuclear facilities, air traffic control, life
    support systems, or weaponry) (“High Risk Activities”); (ii) create multiple applications, accounts, or projects to
    simulate or act as a single application, account, or project (respectively) or otherwise access the Deloitte AI
    Marketplace in a manner intended to avoid incurring fees or exceed usage limits or quotas; (iii) use the Deloitte AI
    Marketplace to operate or enable any telecommunications service or in connection with any application that allows
    Deloitte Member Firm to place calls or to receive calls from any public switched telephone network; or (iv) process or
    store any Data that is subject to the International Traffic in Arms Regulations maintained by the United States
    Department of State. <br><br> d) Deloitte Consulting does not intend uses of the Deloitte AI Marketplace to create
    obligations under Health Insurance Portability and Accountability Act of 1996 as it may be amended from time to time,
    and any regulations issued under it (“HIPAA”), and makes no representations that the Deloitte AI Marketplace satisfies
    HIPAA requirements. If Deloitte Member Firm is (or becomes) a Covered Entity or Business Associate, as defined in
    HIPAA, Deloitte Member Firm will not use the Deloitte AI Marketplace for any purpose or in any manner involving
    Protected Health Information (as defined in HIPAA) unless Deloitte Member Firm has received prior written consent to
    such use from Deloitte Consulting. <br>
    <h2>4. Restrictions on Use</h2> a) The access to the Deloitte AI Marketplace shall be solely for Deloitte Member
    Firm’s benefit and business purposes, and are not intended to be relied upon by any person or entity other than
    Deloitte Member Firm. <br><br> b) Unless otherwise agreed in the order, Deloitte Member Firm is prohibited from and
    shall not: (i) de-compile, reverse engineer or disassemble the Deloitte AI Marketplace or apply any other process or
    procedure to derive the source code thereof, or allow any third party to do so; (ii) sell, re-license, rent, lease,
    provide service bureau or timeshare access to, the Deloitte AI Marketplace; (iii) use the Deloitte AI Marketplace for
    incorporation in any device, article, commodity, product or goods for use by or sale or lease to third parties, or for
    the provision of services to third parties; (iv) except as expressly provided otherwise, modify, publish, share
    dashboards or other components, transmit, reproduce, create derivative works from, distribute, perform, display or in
    any way exploit the Deloitte AI Marketplace in whole or in part; (v) use the Deloitte AI Marketplace or the output
    thereof in any way that is fraudulent or misleading, or violates applicable law, rule or regulation; (vi) perform any
    systematic or automated access or extraction of content from the Deloitte AI Marketplace or use components thereof in
    a manner other than the manner in which such components are incorporated into the Deloitte AI Marketplace. Deloitte
    Member Firm shall not use or permit third parties to use the Deloitte AI Marketplace in any way that (A) interferes
    with or disrupts other network users, network services or network equipment, including spamming; (B) results in the
    introduction of computer worms or viruses; (C) involves the use of false identities; or (D) attempts to gain
    unauthorized entry to any servers or databases through which such Services is provided. <br><br> c) The Deloitte AI
    Marketplace embodies valuable copyright, patent, trademark, trade secret and other intellectual property rights owned
    or licensed by Deloitte Consulting. Deloitte Consulting or its licensors retain all right, title and interest in all
    such proprietary rights and property, and Deloitte Member Firm shall have no interest in any such rights or property,
    nor will anything contained herein constitute a license or grant of any rights in the Deloitte AI Marketplace to
    Deloitte Member Firm with respect to any copyright, patent, trademark, trade secret or any other intellectual property
    right other than as specifically provided herein. <br><br> d) Deloitte Consulting reserves the right to, at any time,
    audit Deloitte Member Firm’s use of the Deloitte AI Marketplace to ensure it is being used in accordance with these
    Terms of Use and to suspend Deloitte Member Firm’s or an individual user’s access thereto if there has a been a
    violation of these terms. <br><br> e) Deloitte Consulting will not claim ownership on any intellectual property rights
    of Deloitte Member Firm in connection with assets placed by such Deloitte Member Firm on the Deloitte AI Marketplace.
    Such intellectual property rights remain solely with Deloitte Member Firm placing the assets. <br><br>
    <h2>5. Performance of Contracts Concluded between Deloitte Member Firms and its Clients</h2> The performance of
    contracts between Deloitte Member Firm and its clients that involve the use of assets obtained from the Deloitte AI
    Marketplace is a matter lying within the sole responsibility of the respective Deloitte Member Firm. With respect to
    any such contracts, Deloitte Consulting neither assumes any warranty for performance of contracts made between
    Deloitte Member Firm and its clients that involve the use of assets obtained from the Deloitte AI Marketplace nor any
    liability for substantive defects or defects of title of the software and services traded thereon. Deloitte Consulting
    shall bear no duty whatsoever to ensure performance of the contracts which have been formed Deloitte Member Firm and
    its clients. <br>
    <h2>6. Updates, Modifications and Enhancements</h2> Except for the standard support and maintenance that Deloitte
    Consulting provides for the Deloitte AI Marketplace, Deloitte Consulting shall have no obligation under this Agreement
    to provide updates, modifications or enhancements to the Deloitte AI Marketplace, or to provide other services with
    respect to the Deloitte AI Marketplace. Any updates, modifications or enhancements provided to Deloitte Member Firm as
    part of the support and maintenance or otherwise shall be and remain the sole and exclusive property of Deloitte
    Consulting. The term “Deloitte AI Marketplace” as used herein shall include such updates, modifications or
    enhancements. <br>
    <h2>7. Confidentiality</h2> a) To the extent that, in connection with this Agreement, either Deloitte Consulting or
    Deloitte Member Firm (each, the “receiving party”) comes into possession of any confidential information of the other
    (the “disclosing party”), it will not disclose such information to any third party without the disclosing party’s
    consent, using at least the same degree of care as it employs in maintaining in confidence its own confidential
    information of a similar nature, but in no event less than a reasonable degree of care. The disclosing party hereby
    consents to the receiving party disclosing such information (aa) as expressly set forth herein; (bb) to contractors
    providing administrative, infrastructure and other support services to the receiving party, in each case, whether
    located within or outside of Germany, provided that such contractors have agreed to be bound by confidentiality
    obligations similar to those in this Section; (cc) as may be required to be disclosed by law, regulation or binding
    order of any court or of any competent governmental or regulatory authority with whose instructions the receiving
    party has to comply; or (dd) to the extent such information (i) is or becomes publicly available other than as the
    result of a disclosure in breach hereof, (ii) becomes available to the receiving party on a non-confidential basis
    from a source that the receiving party believes is not prohibited from disclosing such information to the receiving
    party, (iii) is already known by the receiving party without any obligation of confidentiality with respect thereto,
    or (iv) is developed by the receiving party independently of any disclosures made to the receiving party hereunder.
    Deloitte Member Firm agrees that any Data provided by Deloitte Member Firm hereunder may be used by Deloitte
    Consulting in connection with the Deloitte AI Marketplace. Deloitte Member Firm further agrees that meta data in
    regard to the assets provided/purchased may be used or disclosed by Deloitte Consulting solely for internal purposes
    in connection with Deloitte Consulting’s provision of the Deloitte AI Marketplace, provided that such meta data does
    not contain any Deloitte Member Firm-identifiable information. <br> <br> b) If either party elects to provide any
    suggestions for improvements to the Deloitte AI Marketplace or to the assets of the Deloitte Member Firm on the
    Deloitte AI Marketplace (“Suggestions”) to the other party, then such party grants to the other party a perpetual,
    irrevocable, non-exclusive, worldwide, royalty-free, fully paid up license to reproduce, distribute, make derivative
    works based upon, publicly display, publicly perform, make, have made, sell, offer for sale, import, and otherwise use
    the Suggestions without restriction, including the right to sublicense such rights through multiple tiers, alone or in
    combination. The foregoing license applies regardless of whether the provider has designated the Suggestions as
    confidential. The receiver acknowledges that the provider provides Suggestions AS IS and disclaims all express or
    implied warranties in the Suggestions, and that the receiver's use of Suggestions is at the receiver's own risk.” <br>
    <h2>8. Liability, Damages</h2> a) Except in the event of a party’s recklessness, willful misconduct or bad faith,
    neither party nor any of its directors, officers, members, employees or agents shall be liable to the other party for
    (i) any loss or damage of any kind whatsoever arising out of or connected with any use of or reliance upon the
    Deloitte AI Marketplace, or any deficiency in any Deloitte AI Marketplace or the failure to provide the Deloitte AI
    Marketplace or any other breach of this Agreement, or (ii) for any punitive, exemplary, special, indirect, incidental
    or consequential losses relating to the Deloitte AI Marketplace or this Agreement, in each case whether in an action
    of contract, statute or tort (including negligence). <br><br> b) The Services are provided “as is” and “as available”.
    Deloitte Consulting assumes no liability for any faults or disruptions within Deloitte AI Marketplace for which it
    does not bear fault. Any liability of Deloitte Consulting does not extend to any impairments of the use of the
    Deloitte AI Marketplace in line with this Agreement which result from the Deloitte Member Firm’s improper or incorrect
    utilization. <br><br> c) To the extent that there is any possibility that Deloitte Member Firms or End Users may be
    redirected to databases, websites, services etc. of third parties via the Deloitte AI Marketplace, e.g. as a result of
    the inclusion of links or hyperlinks, Deloitte Consulting shall not be liable either for the accessibility, existence
    or security of such databases or services or for the contents thereof. In particular, Deloitte Consulting shall bear
    no liability for the legal propriety, substantive correctness, completeness, timeliness, etc. thereof. <br><br> d)
    Except as expressly provided in this Terms of Use or a separate written agreement between the Parties, all
    representations and warranties, conditions and other terms implied by statute or common law are, to the fullest extent
    permitted by law, excluded from this Agreement. Without limiting the foregoing, Deloitte Consulting does not warrant
    that the Deloitte AI Marketplace will be error-free, will operate in an uninterrupted fashion, or will meet any
    particular criteria of performance or quality. <br><br> e) This Section 11 relates to rights, obligations and
    limitations of liability in connection with this Agreement only, and does not in any way expand or limit, or have any
    other effect on, any rights, obligations or liabilities under any other agreement, regardless of whether such other
    agreement is between the Parties, or between one Party and any other person or entity. <br><br> 9.
    Indemnification/Third Party Content. a) Deloitte Member Firm shall defend, indemnify and hold harmless Deloitte
    Consulting from and against any claims, damages, losses, liabilities, costs and expenses including reasonable
    attorney’s fees (together “Losses”) arising out of any third-party claim concerning Deloitte Member Firm’s provision
    to the Deloitte AI Marketplace that is unlawful or infringes a third party’s intellectual property rights. <br><br> b)
    As a condition to the indemnity obligations contained herein, Deloitte Consulting shall provide the Deloitte Member
    Firm with prompt notice of any claim for which indemnification shall be sought hereunder and shall cooperate in all
    reasonable respects with the Deloitte Member Firm in connection with any such claim. The Deloitte Member Firm shall be
    entitled to control the handling of any such claim and to defend or settle any such claim in its sole discretion and
    with counsel of its choosing. <br><br>
    <h2>10. Other duties of Deloitte Consulting and Deloitte Member Firm</h2> Each of Deloitte Consulting and Deloitte
    Member Firm has a duty, as applicable, <br> (i) to set up and maintain the necessary data security measures throughout
    the entire term of the contract. For Deloitte Consulting, the foregoing refers to providing appropriate
    administrative, physical and technical safeguards for the protection, confidentiality and integrity of assets and Data
    resident in the Deloitte AI Marketplace. For Deloitte Member Firm, the foregoing refers primarily to careful and
    conscientious handling of logins and passwords; <br><br> (ii) to promptly notify the other party of any technical
    changes occurring within its sphere where such changes are apt to adversely impact either Deloitte Consulting’s
    provision of or the security of Deloitte AI Marketplace, or Deloitte Member Firm’s provision of its assets or Data;
    <br><br> (iii) to assist in investigating attacks by third parties on the Deloitte AI Marketplace or the Deloitte
    Member Firms assets or Data, to the extent such assistance by the Deloitte Member Firm or Deloitte Consulting is
    required; and <br><br> (iv) to maintain appropriate controls designed to protect the Deloitte AI Marketplace or the
    Deloitte Member Firm assets against viruses, malware, or malicious code that causes the Deloitte AI Marketplace or the
    Deloitte Member Firm assets to become impaired, disrupted, erased or incapable of use. <br>
    <h2>11. Governing Law, Disputes</h2> a) This Agreement shall be governed by the laws of the Federal Republic of
    Germany except for the UN Sales Convention (United Nations Convention on Contracts for the International Sale of Goods
    dated 11.4.1980). <br><br> b) Any dispute between the Parties arising out of or in connection with the Deloitte AI
    Marketplace or this shall be resolved in accordance with Deloitte Touche Tohmatsu Limited (“DTTL”) procedures for the
    resolution of disputes arising between the member firms of DTTL <br>
    <h2>12. GDPR</h2> Deloitte Consulting complies with all applicable data protection laws, in particular the General
    Data Protection Regulation (GDPR). Deloitte Consulting acts as Data Controllers in the meaning of Art. 4 (7) GDPR.
    Deloitte Consulting has agreed a contract with its cloud sub-contractor who will process the Data as a Data Processer
    according to Art. 28 GDPR. Deloitte Consulting will transfer Data to its cloud sub-contractor, who hosts the data in
    the EU, only in compliance with the rules of the GDPR. A return transfer of the data from Deloitte Consulting to
    Member Firms located outside of the EU/EEA is covered by the IFAs between the Deloitte Member Firms, which are based
    on the Standard Contractual Clauses and therefore meet the requirements of Art. 44 et seq. GDPR. <br>
    <h2>13. General</h2> Should any provision of this Agreement be or become invalid, this shall not affect the validity
    of the remaining terms. The parties shall in such an event be obliged to cooperate in the creation of terms which
    achieve such legally valid result as comes closest commercially to that of the invalid provision. The above shall
    apply accordingly to the closing of any gaps in the Agreement.
  </div> -->
